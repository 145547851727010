@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .perspective {
    perspective: 1000px;
  }
  .transform-style-3d {
    transform-style: preserve-3d;
  }
  .backface-hidden {
    backface-visibility: hidden;
  }
  .rotate-y-180 {
    transform: rotateY(180deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
/* importe de google un tipo de fuente */

* {
  /* aplico a todos los componentes */
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  /* creo variables globales */
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #c0c6d0;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-ligth: rgba(255, 255, 255, 0.6);
  --transition: all 400ms ease;

  /*ancho de una clase contenedor*/
  --container-width-lg: 75%;
  /*grande*/
  --container-width-md: 86%;
  /*mediano*/
  --container-width-sm: 75%;
  /*chico*/
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  /* quito el scroll de toda la pagina*/
  display: none;
}

body {
  /* defino la fuente que se va a usar en toda la pagina*/
  font-family: "Poppins", sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  /* background-image: url(./assets/bg-texture.png); */
}

/* ========================= ESTILOS GENERALES ========================= */

.container {
  /* pantallas grandes */
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  /* pesadez de la fuente */
  font-weight: 500;
}

h1 {
  /* tamaño de la fuente */
  font-size: 2.5rem;
}

section {
  scroll-margin-top: 100px;
  margin-top: 4rem;
  /* height: 90vh; */
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-ligth);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-ligth);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  /* el 1ro es arriba y abajo, el 2do izq y der*/
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  /* Un elemento block siempre tiene algo de espacio por encima y debajo,
     que lo separa de los demás elementos, y no acepta ningún otro elemento a su lado
     - si no está indicado de otra manera ( float ). */
  width: 100%;
  object-fit: cover;
  /* se ajusta a su contenedor para cubrir todo el espacio*/
}

.carousel {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.carousel__track {
  display: flex;
  width: calc(200%); /* Double the width to accommodate two sets of icons */
  animation: slide 15s linear infinite;
}

.carousel__item {
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  margin-right: 20px; /* Adjust spacing between icons */
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* .carousel {
  position: relative;
  width: 700px;
  height: 700px;
  animation: spin 15s linear infinite;
  transform-style: preserve-3d;
}

.carousel__item {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transform: translate(-50%, -50%);
}

.carousel__item2 {
  width: 100%;
  height: 100%;
  animation: counterspin 15s linear infinite;
}

.carousel__item:nth-child(1) {
  transform: rotate(0deg) translate(175px) rotate(0deg);
}
.carousel__item:nth-child(2) {
  transform: rotate(51.43deg) translate(175px) rotate(-51.43deg);
}
.carousel__item:nth-child(3) {
  transform: rotate(102.86deg) translate(175px) rotate(-102.86deg);
}
.carousel__item:nth-child(4) {
  transform: rotate(154.29deg) translate(175px) rotate(-154.29deg);
}
.carousel__item:nth-child(5) {
  transform: rotate(205.72deg) translate(175px) rotate(-205.72deg);
}
.carousel__item:nth-child(6) {
  transform: rotate(257.15deg) translate(175px) rotate(-257.15deg);
}
.carousel__item:nth-child(7) {
  transform: rotate(308.58deg) translate(175px) rotate(-308.58deg);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes counterspin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
*/
@media screen and (min-width: 1025px) {
  section {
    height: 80vh;
  }
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}
